
import { defineComponent } from "vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { ElForm } from "element-plus";
import MoneyInput from "@/components/form/MoneyInput.vue";
import { useI18n } from "vue-i18n";
import { Transaction } from "@/models/accounting/Transaction";
import { Account } from "@/models/accounting/Account";

export default defineComponent({
  name: "register-form",
  setup() {
    const messages = {
      en: {
        section: "Register Transaction",
        transactionType: "Transaction Type",
        amount: "Amount",
        chooseAccountToAddMoney: "Choose Account to Add Money To",
        chooseAccountTransferTo: "Choose Account to Transfer Money",
        selectAccount: "Select Account",
        add: "Add",
        withdraw: "Withdraw",
        drop: "Drop"
      },
      ar: {
        section: "Register Transaction",
        transactionType: "نوع العملية",
        amount: "المبلغ",
        chooseAccountToAddMoney: "اختر الحساب الذي ترغب بإضافة مبلغ فيه",
        chooseAccountTransferTo: "اختر الحساب الذي ترغب بتحويل مبلغ له",
        selectAccount: "اختر حساب",
        add: "اضف",
        withdraw: "اسحب",
        drop: "ودّع"
      }
    };
    const { t } = useI18n({ messages, useScope: "global" });
    const rules = {
      journalType: [
        {
          required: true,
          message: t("validation.required"),
          trigger: "blur"
        }
      ],
      amountAmount: [
        {
          required: true,
          message: t("validation.required"),
          trigger: "blur"
        },
        {
          type: "number",
          min: 0
        }
      ],
      debitedAccountID: [
        {
          required: true,
          message: t("validation.required"),
          trigger: "blur"
        }
      ],
      creditedAccountID: [
        {
          required: true,
          message: t("validation.required"),
          trigger: "blur"
        }
      ]
    };
    return { t, rules };
  },
  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },
  props: {
    initRegisterTransaction: {
      type: Transaction,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      default: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  async beforeCreate() {
    this.registerAccount = await this.$http.get<Account>(
      `${Account.ENDPOINT}?ref_id=${this.$ctx.currentRegister.id}`
    );
  },
  data() {
    const accounts = new Array<Account>();
    const registerAccount = new Account();
    return {
      registerAccount,
      accounts,
      registerTransaction: this.initRegisterTransaction,
      error: { title: "", body: "" }
    };
  },

  methods: {
    async searchAccounts(query: string) {
      try {
        const url = `${Account.ENDPOINT}?q=${query}`;
        const accounts = await this.$http.get<Account[]>(url);
        this.accounts = accounts;
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        this.accounts = [];
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      this.$globalEmitter.emit("money-input-reset");
      if (form) form.resetFields();
    },

    async preparePayload() {
      this.registerTransaction.preparePayload(
        this.$ctx.currentLocation,
        this.$ctx.currentRegister,
        this.$ctx.currentUser
      );
      this.registerTransaction.currency = this.registerTransaction.amountDinero.getCurrency();
      if (this.registerTransaction.journalType === "add") {
        this.registerTransaction.debitedAccountID = this.registerAccount.id;
        this.registerTransaction.journalType = "deposit";
        this.registerTransaction.label = "registers_add";
      }

      if (this.registerTransaction.journalType === "withdraw") {
        this.registerTransaction.creditedAccountID = this.registerAccount.id;
        this.registerTransaction.journalType = "withdrawal";
        this.registerTransaction.label = "registers_withdraw";
      }

      if (this.registerTransaction.journalType === "drop") {
        this.registerTransaction.journalType = "transfer";
        this.registerTransaction.label = "registers_drop";
        try {
          const url = `${Account.ENDPOINT}?ref_id=${this.$ctx.currentLocation.id}`;
          const accounts = await this.$http.get<Account[]>(url);
          this.registerTransaction.creditedAccountID = this.registerAccount.id;
          this.registerTransaction.debitedAccountID = accounts[0].id;
        } catch (error) {
          this.$alertModal.showDanger({ title: error.title });
          return;
        }
      }
    },

    async validateSubmit(formName: string) {
      this.preparePayload();
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.registerTransaction);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }
          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
