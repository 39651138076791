<template>
  <div class="m-3" style="height:90vh">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <action-button
        :title="$t('register#1')"
        faIcon="cash-register"
        @click.prevent="$router.push($Route.ACCOUNTING_REGISTERS_REGISTER)"
      >
      </action-button>
    </div>

    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select
        class="input-label"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/accounting/transactions')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full md:w-6/12"
        prefix-icon="el-icon-search"
        v-model="searchTerm"
      />

      <div class="w-full md:w-3/12">
        <template v-if="$can('create', '/accounting/transactions')">
          <horizontal-button
            :title="$t('views.accounting.transactions.newTransaction')"
            @click.prevent="handleRegisterClick"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { CellClickedEvent, ColDef, GridOptions } from "ag-grid-community";
import HorizontalButton from "@/components/HorizontalButton.vue";
import ActionButton from "@/components/ActionButton.vue";

import { RegisterStatus } from "@/models/company/Register";
import { useI18n } from "vue-i18n";
import { formatDinero } from "@/utils/money";

export const registerI18nMessages = {
  en: {
    no: "Register",
    status: "Status",
    openingFloating: "Opening Floating",
    closingFloating: "Closing Floating",
    totalCashIn: "Total Cash In",
    totalCashOut: "Total Cash Out",
    balance: "Balance",
    moneyDropped: "Money Dropped",
    openRegister: "Open Register",
    closeRegister: "Close Register"
  },
  ar: {
    no: "التسلسل",
    status: "الحالة",
    openingFloating: "المبلغ الدوار عند الفتح",
    closingFloating: "المبلغ الدوار عند الإغلاق",
    totalCashIn: "النقد الداخل",
    totalCashOut: "النقد الخارج",
    balance: "الرصيد",
    moneyDropped: "النقد المودّع"
  }
};

export default defineComponent({
  name: "registers",

  setup() {
    const { t } = useI18n({
      messages: registerI18nMessages,
      unescape: "global"
    });

    return {
      t
    };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: RegisterStatus[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue,
    HorizontalButton,
    ActionButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.$t("no"),
        field: "no",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        valueFormatter: params => {
          const register = params.node?.data as RegisterStatus;
          return "Register #" + register.no;
        }
      },
      {
        headerName: this.t("status"),
        field: "status",
        cellStyle: ({ value }) => {
          const base = { textAlign: "center", backgroundColor: "transparent" };

          if (value == "open") {
            base["backgroundColor"] = "var(--success-bg-color)";
          } else if (value == "close") {
            base["backgroundColor"] = "var(--danger-bg-color)";
          }

          return base;
        }
      },
      {
        headerName: this.t("openingFloating"),
        field: "openingFloating",
        valueFormatter: params => {
          const register = params.node?.data as RegisterStatus;
          return formatDinero(register.openingFloatingDinero);
        }
      },
      {
        headerName: this.t("closingFloating"),
        field: "closingFloating",
        valueFormatter: params => {
          const register = params.node?.data as RegisterStatus;
          return formatDinero(register.closingFloatingDinero);
        }
      },
      {
        headerName: this.t("totalCashIn"),
        field: "totalCashIn",
        valueFormatter: params => {
          const register = params.node?.data as RegisterStatus;
          return formatDinero(register.totalCashInDinero);
        }
      },
      {
        headerName: this.t("totalCashOut"),
        field: "totalCashOut",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const register = params.node?.data as RegisterStatus;
          return "- " + formatDinero(register.totalCashOutDinero);
        }
      },
      {
        headerName: this.t("balance"),
        field: "balance",
        valueFormatter: params => {
          const register = params.node?.data as RegisterStatus;
          return formatDinero(register.balanceDinero);
        }
      },
      {
        headerName: this.t("moneyDropped"),
        field: "moneyDropped",
        valueFormatter: params => {
          const register = params.node?.data as RegisterStatus;
          return formatDinero(register.moneyDroppedDinero);
        }
      },
      {
        headerName: this.t("action"),
        valueFormatter: params => {
          const register = params.node?.data as RegisterStatus;
          return register.status === "open"
            ? this.t("closeRegister")
            : this.t("openRegister");
        },
        onCellClicked: (params: CellClickedEvent) => {
          const register = params.node?.data as RegisterStatus;
          if (register.status === "close")
            this.$router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER);
          else this.$router.push(this.$Route.SALES_REGISTERS_CLOSE_REGISTER);
        }
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();
  },

  watch: {
    searchTerm() {
      this.filterItems();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();
      try {
        const registerRes = await this.$http.get<RegisterStatus>(
          `/accounting/registerstatus/${this.$ctx.currentRegister.id}`
        );
        this.rowData = [RegisterStatus.from(registerRes)];
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async applyToSelected() {
      if (this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          this.gridOptions.api?.exportDataAsCsv({
            onlySelected: true
          });
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.exportSuccess")
          });

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    filterItems() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    },

    handleRegisterClick() {
      this.$router.push(
        this.$Route.ACCOUNTING_REGISTERS_REGISTER.replace(
          ":id",
          this.$ctx.currentRegister.id!
        )
      );
    }
  }
});
</script>

<style lang="scss">
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #f5f5f5,
      row-border-color: transparent,
      row-hover-color: null,
      checkbox-checked-color: var(--primary-bg-color),
      range-selection-border-color: var(--primary-bg-color)
    )
  );
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-content-cell-label {
  justify-content: center;
}
</style>
