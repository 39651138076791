
import { defineComponent } from "vue";
import NewExpenseForm from "./NewExpenseForm.vue";

// models

import { useI18n } from "vue-i18n";
import { Expense } from "./Company.vue";

export default defineComponent({
  components: { NewExpenseForm },
  name: "new-expense",

  setup() {
    const { t } = useI18n({
      messages: {
        en: { expenseWasCreated: "expense was created successfully" },
        ar: { expenseWasCreated: "تم انشاء المنتج بنجاح" }
      }
    });
    return { t };
  },

  data() {
    return {
      initExpense: new Expense()
    };
  },

  methods: {
    async submit(expense: Expense) {
      // try {
      //   await this.$http.post<Item>(Item.ENDPOINT, item);
      // } catch (error) {
      //   return error;
      // }
    }
  }
});
