<template>
  <div>
    <el-form
      @submit.prevent="validateSubmit('registerForm')"
      novalidate
      :model="registerTransaction"
      label-position="top"
      ref="registerForm"
      class="flex flex-col items-center"
      :rules="rules"
    >
      <div class="input-section">
        <div class="flex flex-col md:flex-row justify-end">
          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.saveChanges')"
              isSuccess
              faIcon="save"
              @click.prevent="validateSubmit('registerForm')"
            />
          </div>

          <div class="md:w-3/12 m-2" v-if="enableClearForm">
            <horizontal-button
              :title="$t('actions.clearButton')"
              isDanger
              faIcon="trash"
              @click.prevent="clearForm('registerForm')"
            />
          </div>
        </div>
      </div>

      <!-- @submit errors -->
      <alert-badge
        isDanger
        :title="error.title"
        :body="error.body"
        @dismissed="
          () => {
            error.title = '';
            error.body = '';
          }
        "
      />

      <div class="input-section">
        <h1>{{ t("section") }}</h1>
        <div class="input-group">
          <el-form-item
            prop="journalType"
            :label="t('transactionType')"
            class="input-label"
            ref="journalType"
          >
            <el-select
              v-model="registerTransaction.journalType"
              placeholder="Select"
              size="large"
            >
              <el-option :label="$t('add')" value="add" />
              <el-option :label="$t('withdraw')" value="withdraw" />
              <el-option :label="$t('drop')" value="drop" />
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div class="input-section">
        <div class="input-group">
          <el-form-item
            prop="amountAmount"
            class="w-1/2 label input-label"
            :label="t('amount')"
            ref="amount"
          >
            <money-input
              id="transaction-amount"
              :currency="$ctx.currency"
              v-model="registerTransaction.amountAmount"
              @dinero-created="
                dinero => (registerTransaction.amountDinero = dinero)
              "
            />
          </el-form-item>
        </div>
      </div>
      <div
        v-if="registerTransaction.journalType === 'add'"
        class="input-section"
      >
        <div class="input-group">
          <el-form-item
            prop="creditedAccountID"
            :label="t('chooseAccountToAddMoney')"
            class="input-label"
          >
            <el-select
              v-model="registerTransaction.creditedAccountID"
              :placeholder="t('selectAccount')"
              size="large"
              :trigger-on-focus="false"
              value-key="name"
              filterable
              remote
              :remote-method="searchAccounts"
              :clearable="true"
            >
              <el-option
                v-for="account in accounts"
                :key="account.id"
                :label="$ctx.locale === 'en' ? account.enName : account.arName"
                :value="account.id"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div
        v-if="registerTransaction.journalType === 'withdraw'"
        class="input-section"
      >
        <div class="input-group">
          <el-form-item
            size="large"
            prop="debitedAccountID"
            :label="t('chooseAccountTransferTo')"
            class="input-label"
            label-width="200px"
          >
            <el-select
              v-model="registerTransaction.debitedAccountID"
              :placeholder="t('selectAccount')"
              size="large"
              :trigger-on-focus="false"
              value-key="name"
              filterable
              remote
              :remote-method="searchAccounts"
            >
              <el-option
                v-for="account in accounts"
                :key="account.id"
                :label="$ctx.locale === 'en' ? account.enName : account.arName"
                :value="account.id"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { ElForm } from "element-plus";
import MoneyInput from "@/components/form/MoneyInput.vue";
import { useI18n } from "vue-i18n";
import { Transaction } from "@/models/accounting/Transaction";
import { Account } from "@/models/accounting/Account";

export default defineComponent({
  name: "register-form",
  setup() {
    const messages = {
      en: {
        section: "Register Transaction",
        transactionType: "Transaction Type",
        amount: "Amount",
        chooseAccountToAddMoney: "Choose Account to Add Money To",
        chooseAccountTransferTo: "Choose Account to Transfer Money",
        selectAccount: "Select Account",
        add: "Add",
        withdraw: "Withdraw",
        drop: "Drop"
      },
      ar: {
        section: "Register Transaction",
        transactionType: "نوع العملية",
        amount: "المبلغ",
        chooseAccountToAddMoney: "اختر الحساب الذي ترغب بإضافة مبلغ فيه",
        chooseAccountTransferTo: "اختر الحساب الذي ترغب بتحويل مبلغ له",
        selectAccount: "اختر حساب",
        add: "اضف",
        withdraw: "اسحب",
        drop: "ودّع"
      }
    };
    const { t } = useI18n({ messages, useScope: "global" });
    const rules = {
      journalType: [
        {
          required: true,
          message: t("validation.required"),
          trigger: "blur"
        }
      ],
      amountAmount: [
        {
          required: true,
          message: t("validation.required"),
          trigger: "blur"
        },
        {
          type: "number",
          min: 0
        }
      ],
      debitedAccountID: [
        {
          required: true,
          message: t("validation.required"),
          trigger: "blur"
        }
      ],
      creditedAccountID: [
        {
          required: true,
          message: t("validation.required"),
          trigger: "blur"
        }
      ]
    };
    return { t, rules };
  },
  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },
  props: {
    initRegisterTransaction: {
      type: Transaction,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      default: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  async beforeCreate() {
    this.registerAccount = await this.$http.get<Account>(
      `${Account.ENDPOINT}?ref_id=${this.$ctx.currentRegister.id}`
    );
  },
  data() {
    const accounts = new Array<Account>();
    const registerAccount = new Account();
    return {
      registerAccount,
      accounts,
      registerTransaction: this.initRegisterTransaction,
      error: { title: "", body: "" }
    };
  },

  methods: {
    async searchAccounts(query: string) {
      try {
        const url = `${Account.ENDPOINT}?q=${query}`;
        const accounts = await this.$http.get<Account[]>(url);
        this.accounts = accounts;
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        this.accounts = [];
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      this.$globalEmitter.emit("money-input-reset");
      if (form) form.resetFields();
    },

    async preparePayload() {
      this.registerTransaction.preparePayload(
        this.$ctx.currentLocation,
        this.$ctx.currentRegister,
        this.$ctx.currentUser
      );
      this.registerTransaction.currency = this.registerTransaction.amountDinero.getCurrency();
      if (this.registerTransaction.journalType === "add") {
        this.registerTransaction.debitedAccountID = this.registerAccount.id;
        this.registerTransaction.journalType = "deposit";
        this.registerTransaction.label = "registers_add";
      }

      if (this.registerTransaction.journalType === "withdraw") {
        this.registerTransaction.creditedAccountID = this.registerAccount.id;
        this.registerTransaction.journalType = "withdrawal";
        this.registerTransaction.label = "registers_withdraw";
      }

      if (this.registerTransaction.journalType === "drop") {
        this.registerTransaction.journalType = "transfer";
        this.registerTransaction.label = "registers_drop";
        try {
          const url = `${Account.ENDPOINT}?ref_id=${this.$ctx.currentLocation.id}`;
          const accounts = await this.$http.get<Account[]>(url);
          this.registerTransaction.creditedAccountID = this.registerAccount.id;
          this.registerTransaction.debitedAccountID = accounts[0].id;
        } catch (error) {
          this.$alertModal.showDanger({ title: error.title });
          return;
        }
      }
    },

    async validateSubmit(formName: string) {
      this.preparePayload();
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.registerTransaction);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }
          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
</script>

<style lang="postcss">
.input-group {
  @apply flex flex-col w-full items-center;
}
.input-section {
  @apply rounded w-full justify-center;
}
@screen lg {
  .input-section {
    @apply w-7/12;
  }
}
@screen md {
  .input-section {
    @apply flex-row;
  }
  .input-group {
    @apply flex-row;
  }
  .input-group > .input-label {
    @apply w-6/12;
  }
}
</style>
