
import { defineComponent, ref } from "vue";

import { useHTTP } from "@/plugins/http";

// components
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import RegisterForm from "./RegisterForm.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { Transaction } from "@/models/accounting/Transaction";

export default defineComponent({
  name: "register",
  components: {
    LoadingSpinner,
    RegisterForm,
    AlertBadge
  },

  setup() {
    const initRegisterTransaction = ref(new Transaction());
    const http = useHTTP();
    const loading = ref(false);
    const error = ref({ title: "", body: "" });

    const submit = async (registerTransaction: Transaction) => {
      try {
        loading.value = true;
        const url = `${Transaction.ENDPOINT}`;
        await http.post<Transaction>(url, registerTransaction);
        // initRegisterTransaction.value = Transaction.from(resp);
        loading.value = false;
      } catch (err) {
        error.value.title = err.title;
        error.value.body = err.body;
        loading.value = false;
        return err;
      }
      return false;
    };

    return {
      initRegisterTransaction,
      loading,
      update: submit,
      error
    };
  }
});
