<template>
  <div class="m-3" style="height:90vh">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select
        class="input-label"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/accounting/transactions')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full md:w-6/12"
        prefix-icon="el-icon-search"
        v-model="searchTerm"
      />

      <div class="w-full md:w-3/12">
        <template v-if="$can('create', '/accounting/transactions')">
          <horizontal-button
            :title="t('newPay')"
            @click.prevent="$router.push($Route.ACCOUNTING_HR_NEW_PAY)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import HorizontalButton from "@/components/HorizontalButton.vue";

import { useI18n } from "vue-i18n";
import { parseFormat } from "@/utils/date";
import { formatDinero } from "@/utils/money";
import UUIDBase from "@/models/UUIDBase";
import { Dinero as DineroType } from "dinero.js";
import Dinero from "dinero.js";

export const payI18nMessages = {
  en: {
    newPay: "New Pay",
    amount: "Amount",
    employeeName: "Employee Name",
    employeeType: "Employee Type",
    payType: "Pay Type",
    date: "Date",
    period: "Period"
  },
  ar: {
    newPay: "عملية دفع جديدة",
    amount: "المبلغ",
    employeeName: "اسم الموظف",
    employeeType: "نوع الموظف",
    payType: "نوع الدفع",
    date: "التاريخ",
    period: "المدة"
  }
};

export class Pay extends UUIDBase {
  amount?: number;
  amountCurrency?: string;
  amountPrecision?: number;
  amountDinero?: DineroType;

  employeeId?: string;
  employeeName?: string;
  employeeType?: string;

  payType?: string;

  date?: Date;
  period?: Date[];
  notes?: string;
}

export default defineComponent({
  name: "hr",

  setup() {
    const { t } = useI18n({
      messages: payI18nMessages,
      unescape: "global"
    });

    return {
      t
    };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: Pay[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("date"),
        field: "date",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        valueFormatter: params => {
          const pay = params.node?.data as Pay;
          return parseFormat(pay.date);
        }
      },
      {
        headerName: this.t("period"),
        field: "period",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const pay = params.node?.data as Pay;
          return (
            (pay.period && pay.period[0].toLocaleDateString("en-GB")) +
            " - " +
            (pay.period && pay.period[1].toLocaleDateString("en-GB"))
          );
        }
      },
      {
        headerName: this.t("amount"),
        field: "amount",
        valueFormatter: params => {
          const pay = params.node?.data as Pay;
          return formatDinero(pay.amountDinero);
        }
      },
      {
        headerName: this.t("employeeName"),
        field: "employeeName"
      },
      {
        headerName: this.t("employeeType"),
        field: "employeeType"
      },
      {
        headerName: this.t("payType"),
        field: "payType"
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view category
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(
        this.$Route.ACCOUNTING_TRANSACTIONS_TRANSACTION.replace(
          ":id",
          event.data.id
        )
      );
    };
  },

  watch: {
    searchTerm() {
      this.filterItems();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();
      const pay = {
        employeeName: "ahmed",
        employeeType: "freelance",
        payType: "salary",
        period: [new Date(), new Date()],
        date: new Date(),
        amount: 1500,
        amountDinero: Dinero({
          amount: 1500,
          currency: "IQD",
          precision: 0
        })
      } as Pay;

      this.rowData = [pay];
      // try {
      //   const data = await this.$http.get<Transaction[]>(Transaction.ENDPOINT);
      //   this.rowData = data.map(transaction => Transaction.from(transaction));
      // } catch (error) {
      //   this.$alertModal.showDanger({
      //     title: error.title,
      //     body: error.body
      //   });
      // }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async applyToSelected() {
      if (this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          this.gridOptions.api?.exportDataAsCsv({
            onlySelected: true
          });
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.exportSuccess")
          });

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    filterItems() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
</script>

<style lang="scss">
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #f5f5f5,
      row-border-color: transparent,
      row-hover-color: null,
      checkbox-checked-color: var(--primary-bg-color),
      range-selection-border-color: var(--primary-bg-color)
    )
  );
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-content-cell-label {
  justify-content: center;
}
</style>
