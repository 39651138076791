<template>
  <div>
    <el-form
      @submit.prevent="validateSubmit('billForm')"
      novalidate
      :model="vendorBill"
      :rules="rules"
      label-position="top"
      ref="billForm"
      class="flex flex-col items-center"
    >
      <div class="input-section">
        <div class="flex flex-col md:flex-row justify-end">
          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.saveChanges')"
              isSuccess
              faIcon="save"
              @click.prevent="validateSubmit('billForm')"
            />
          </div>

          <div class="md:w-3/12 m-2" v-if="enableClearForm">
            <horizontal-button
              :title="$t('actions.clearButton')"
              isDanger
              faIcon="trash"
              @click.prevent="clearForm('billForm')"
            />
          </div>
        </div>
      </div>

      <!-- @submit errors -->
      <alert-badge
        isDanger
        :title="error.title"
        :body="error.body"
        @dismissed="
          () => {
            error.title = '';
            error.body = '';
          }
        "
      />

      <!-- Item Name -->
      <div class="input-section">
        <h1>{{ t("sections.newBill") }}</h1>

        <el-form-item
          :label="t('vendorName')"
          prop="vendorName"
          class="input-label"
        >
          <el-select
            v-model="vendorBill.vendorID"
            filterable
            clearable
            class="w-full"
            required
          >
            <el-option
              v-for="vendor in vendors"
              :key="vendor.id"
              :label="vendor.name"
              :value="vendor.id"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                {{ vendor.name }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <!-- billDate -->
      <div class="input-section">
        <el-form-item
          :label="t('dueDate')"
          prop="billDueDate"
          class="input-label"
        >
          <el-date-picker
            id="due-date"
            v-model="vendorBill.billDueDate"
            type="date"
            :placeholder="t('dueDate')"
          >
          </el-date-picker>
        </el-form-item>
      </div>

      <!-- bill amount -->
      <div class="input-section">
        <el-form-item
          :label="t('totalAmount')"
          prop="totalDueAmount"
          class="input-label"
        >
          <money-input
            id="total-amount"
            :currency="$ctx.currency"
            v-model="vendorBill.totalDueAmount"
            @dinero-created="dinero => (vendorBill.totalDueDinero = dinero)"
            ref="totalAmountRef"
          />
        </el-form-item>
        <div class="input-group">
          <el-form-item
            :label="t('paidAmount')"
            prop="totalPaidAmount"
            class="input-label"
          >
            <money-input
              id="total-paid"
              :currency="$ctx.currency"
              v-model="vendorBill.totalPaidAmount"
              @dinero-created="dinero => (vendorBill.totalPaidDinero = dinero)"
              ref="paidAmountRef"
              :key="JSON.stringify(fullPaid)"
            />
          </el-form-item>
          <el-form-item class="input-label">
            <horizontal-button
              :title="$t('fullyPaid')"
              :isSuccess="true"
              @click.prevent="fullPay"
              class="fully-paid-button"
            />
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// models
import VendorBill, { vendorBillI18nMessages } from "@/models/vendor/VendorBill";
import Vendor from "@/models/vendor/Vendor";

// components
import HorizontalButton from "@/components/HorizontalButton.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { ElForm } from "element-plus";
import MoneyInput from "@/components/form/MoneyInput.vue";

import { useI18n } from "vue-i18n";

interface Option {
  name: string;
  values: string[];
}

export default defineComponent({
  name: "bill-form",

  setup() {
    const messages = {
      en: {
        ...vendorBillI18nMessages.en,

        sections: {
          newBill: "New Bill"
        },
        fullyPaid: "Fully Paid"
      },

      ar: {
        ...vendorBillI18nMessages.ar,
        sections: {
          newBill: "فاتورة جديدة"
        },
        fullyPaid: "دفع المبلغ الكامل"
      }
    };

    const { t } = useI18n({ messages, useScope: "global" });

    const rules = {
      vendorID: [{ required: true, message: t("validation.required") }],
      totalPaidAmount: [{ required: true, message: t("validation.required") }],
      totalDueAmount: [{ required: true, message: t("validation.required") }],
      billDueDate: [{ required: true, message: t("validation.required") }]
    };

    return { t, rules };
  },

  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },
  props: {
    initBill: {
      type: VendorBill,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      default: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },
  // I have the choice to use v-model but would make this code
  // incredibly complicated!
  // I chose to alias/copy props, even though it might be against vue style guide
  // the parent hence passes an initial copy of the item and variants and DOES NOT
  // expect those inital copies to be synchronized!
  data() {
    return {
      vendorBill: this.initBill,
      vendors: new Array<Vendor>(),
      fullPaid: false,
      error: { title: "", body: "" }
    };
  },
  async beforeMount() {
    try {
      const vendorResp = await this.$http.get<Vendor[]>(Vendor.ENDPOINT);
      this.vendors = vendorResp.map(vendor => Vendor.from(vendor));
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },
  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      this.vendorBill = new VendorBill();
      this.fullPaid = false;
      this.$globalEmitter.emit("money-input-reset");
      form.resetFields();
      this.fullPay();
    },

    async validateSubmit(formName: string) {
      this.vendorBill.currency = this.vendorBill.totalPaidDinero?.getCurrency();
      this.vendorBill.precision = this.vendorBill.totalPaidDinero?.getPrecision();
      this.vendorBill.billDate = new Date();
      this.vendorBill.totalBalanceAmount =
        this.vendorBill.totalDueAmount - this.vendorBill.totalPaidAmount;

      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.vendorBill);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    },

    fullPay() {
      this.vendorBill.totalPaidAmount = this.vendorBill.totalDueAmount;
      this.fullPaid = true;
    }
  }
});
</script>

<style lang="postcss" scoped>
h1 {
  @apply text-lg py-3 font-bold;
}

h2 {
  @apply py-3 font-bold;
}

h3 {
  @apply py-3 font-bold;
}

.input-group {
  @apply flex flex-col justify-between w-full items-end;
}

.input-group-row {
  @apply flex justify-between w-full;
}

.input-group-row > label {
  @apply w-6/12;
}

.input-section {
  @apply rounded w-full justify-center;
}

@screen lg {
  .input-section {
    @apply w-7/12;
  }
}

@screen md {
  .input-section {
    @apply flex-row;
  }

  .input-group {
    @apply flex-row;
  }

  .input-group > .input-label {
    @apply w-6/12;
  }
}

.remove-button {
  @apply text-lg;
  color: var(--danger-text-color);
  align-self: center;
  justify-self: center;
  cursor: pointer;
  margin: 0.5rem;
}

.remove-button:hover {
  color: var(--danger-hover-bg-color);
  align-self: center;
  justify-self: center;
}

.fully-paid-button {
  height: 40px;
}

hr.solid {
  border-top: 1px solid var(--tertiary-bg-color);
}

.hide {
  display: none;
}

.input:hover + .hide {
  display: flex;
  justify-content: center;
}

.danger {
  color: var(--danger-text-color);
  background: var(--danger-bg-color);
}

.success {
  color: var(--success-text-color);
  background: var(--success-bg-color);
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f3f3f3;
}
</style>
