
import { defineComponent } from "vue";

// models
import VendorBill, { vendorBillI18nMessages } from "@/models/vendor/VendorBill";
import Vendor from "@/models/vendor/Vendor";

// components
import HorizontalButton from "@/components/HorizontalButton.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { ElForm } from "element-plus";
import MoneyInput from "@/components/form/MoneyInput.vue";

import { useI18n } from "vue-i18n";

interface Option {
  name: string;
  values: string[];
}

export default defineComponent({
  name: "bill-form",

  setup() {
    const messages = {
      en: {
        ...vendorBillI18nMessages.en,

        sections: {
          newBill: "New Bill"
        },
        fullyPaid: "Fully Paid"
      },

      ar: {
        ...vendorBillI18nMessages.ar,
        sections: {
          newBill: "فاتورة جديدة"
        },
        fullyPaid: "دفع المبلغ الكامل"
      }
    };

    const { t } = useI18n({ messages, useScope: "global" });

    const rules = {
      vendorID: [{ required: true, message: t("validation.required") }],
      totalPaidAmount: [{ required: true, message: t("validation.required") }],
      totalDueAmount: [{ required: true, message: t("validation.required") }],
      billDueDate: [{ required: true, message: t("validation.required") }]
    };

    return { t, rules };
  },

  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },
  props: {
    initBill: {
      type: VendorBill,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      default: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },
  // I have the choice to use v-model but would make this code
  // incredibly complicated!
  // I chose to alias/copy props, even though it might be against vue style guide
  // the parent hence passes an initial copy of the item and variants and DOES NOT
  // expect those inital copies to be synchronized!
  data() {
    return {
      vendorBill: this.initBill,
      vendors: new Array<Vendor>(),
      fullPaid: false,
      error: { title: "", body: "" }
    };
  },
  async beforeMount() {
    try {
      const vendorResp = await this.$http.get<Vendor[]>(Vendor.ENDPOINT);
      this.vendors = vendorResp.map(vendor => Vendor.from(vendor));
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },
  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      this.vendorBill = new VendorBill();
      this.fullPaid = false;
      this.$globalEmitter.emit("money-input-reset");
      form.resetFields();
      this.fullPay();
    },

    async validateSubmit(formName: string) {
      this.vendorBill.currency = this.vendorBill.totalPaidDinero?.getCurrency();
      this.vendorBill.precision = this.vendorBill.totalPaidDinero?.getPrecision();
      this.vendorBill.billDate = new Date();
      this.vendorBill.totalBalanceAmount =
        this.vendorBill.totalDueAmount - this.vendorBill.totalPaidAmount;

      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.vendorBill);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    },

    fullPay() {
      this.vendorBill.totalPaidAmount = this.vendorBill.totalDueAmount;
      this.fullPaid = true;
    }
  }
});
