
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import HorizontalButton from "@/components/HorizontalButton.vue";

import { useI18n } from "vue-i18n";
import { parseFormat } from "@/utils/date";
import { formatDinero } from "@/utils/money";
import UUIDBase from "@/models/UUIDBase";
import { Dinero as DineroType } from "dinero.js";
import Dinero from "dinero.js";

export const expenseI18nMessages = {
  en: {
    date: "Date",
    expenseDate: "Expense Date",
    payee: "Payee",
    expenseType: "Expense Type",
    notes: "Notes",
    amount: "Amount",
    employee: "Employee"
  },
  ar: {
    date: "التاريخ",
    expenseDate: "تاريج الصرف",
    payee: "المستفيد",
    expenseType: "نوع الصرف",
    notes: "الملاحظات",
    amount: "المبلغ",
    employee: "الموظف"
  }
};
export class Expense extends UUIDBase {
  expenseDate?: Date;

  payee?: string;
  expenseType?: string;
  notes?: string;

  amount?: number;
  amountCurrency?: string;
  amountPrecision?: number;
  amountDinero?: DineroType;
}

export default defineComponent({
  name: "company",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {...expenseI18nMessages.en},
        ar: {...expenseI18nMessages.ar}
      },
      unescape: "global"
    });

    return {
      t
    };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: Expense[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("date"),
        field: "createdAt",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        valueFormatter: params => {
          const expense = params.node?.data as Expense;
          return parseFormat(expense.createdAt);
        }
      },
      {
        headerName: this.t("amount"),
        field: "amount",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const expense = params.node?.data as Expense;
          return formatDinero(expense.amountDinero);
        }
      },
      {
        headerName: this.t("expenseType"),
        field: "expenseType"
      },
      {
        headerName: this.t("payee"),
        field: "payee"
      },
      {
        headerName: this.t("employee"),
        field: "employee"
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view category
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(
        this.$Route.ACCOUNTING_TRANSACTIONS_TRANSACTION.replace(
          ":id",
          event.data.id
        )
      );
    };
  },

  watch: {
    searchTerm() {
      this.filterItems();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        // const data = await this.$http.get<Transaction[]>(Transaction.ENDPOINT);
        // this.rowData = data.map(transaction => Transaction.from(transaction));
        const expense = {
          createdAt: new Date(),
          amount: 1000,
          amountCurrency: "IQD",
          amountPrecision: 0,
          amountDinero: Dinero({
            amount: 10000,
            currency: "IQD",
            precision: 0
          }),

          expenseType: "Electricity",
          payee: "Electricity Office"
        } as Expense;

        this.rowData = [expense, expense];
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async applyToSelected() {
      if (this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          this.gridOptions.api?.exportDataAsCsv({
            onlySelected: true
          });
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.exportSuccess")
          });

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    filterItems() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
