<template>
  <div class="m-3" style="height:90vh">
    <div class="flex flex-col items-center justify-between gap-2 my-6">
      <div class="flex w-full items-center gap-2 md:flex-row">
        <el-select
          class="input-label"
          v-model="selectedValue"
          @change="applyToSelected"
        >
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </el-select>

        <el-input
          :placeholder="$t('views.common.listView.searchRows')"
          class="w-full md:w-6/12"
          prefix-icon="el-icon-search"
          v-model="searchTerm"
          :onchange="filterItems()"
        />
      </div>
      <div
        v-if="rowData"
        class="w-full flex justify-between items-center bg-white px-4 py-6"
      >
        <h1 class="text-2xl capitalize" v-if="rowData.invoices">
          {{ rowData.invoices[0].customer.contactDisplayName }}
        </h1>
        <h1 class="text-2xl" v-if="rowData.totalBalanceAmount">
          {{ t("balance") + ": " + getDineroValue(rowData.totalBalanceAmount) }}
        </h1>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData.invoices"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import actionDropdown from "@/views/accounting/customers/CustomerInvoiceMakePayment.vue";

import { i18nOrderMessages, OrderPayment } from "@/models/sales/Order";
import { useI18n } from "vue-i18n";
import { formatDinero } from "@/utils/money";
import Dinero, { Currency } from "dinero.js";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import { CustomerInvoice } from "@/models/sales/CustomerInvoice";

interface CustomerBalanceResponse {
  totalBalanceAmount?: number;
  currency?: string;
  precision?: number;
  invoices?: CustomerInvoice[];
}

export default defineComponent({
  name: "customers",

  setup() {
    const { t } = useI18n({
      messages: {
        ...i18nOrderMessages,
        en: {
          invoiceNo: "Invoice #",
          invoiceDate: "Invoice Date",
          dueDate: "Due Date",
          type: "Type",
          amountPaid: "Amount Paid",
          balance: "Balance",
          action: "Action",
          total: "Total",
          newCustomer: "New Customer"
        },
        ar: {
          invoiceNo: "رقم الفاتورة",
          invoiceDate: "تاريخ الفاتورة",
          dueDate: "تاريخ الاستحقاق",
          type: "نوع الفاتورة",
          amountPaid: "المبلغ المدفوع",
          balance: "الرصيد",
          action: "العمليات",
          total: "المجموع",
          newCustomer: "زبون جديد"
        }
      },
      useScope: "global"
    });

    return {
      t
    };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: CustomerBalanceResponse = {};
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    const router = useRouter();
    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      router,
      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue
    // HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("invoiceNo"),
        field: "externalID",
        cellStyle: { textAlign: "center" },
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t("invoiceDate"),
        field: "createdAt",
        valueFormatter: params => {
          const createdAt = params.node?.data.createdAt;
          const formatted = dayjs(parseInt(createdAt)).format("DD/MM/YYYY");
          return formatted;
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("dueDate"),
        field: "dueDate",
        valueFormatter: params => {
          const billDueDate = params.node?.data.billDueDate;
          const formatted = dayjs(parseInt(billDueDate)).format("DD/MM/YYYY");
          return formatted;
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("type"),
        field: "paymentType",
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("amountPaid"),
        field: "amountPaid",
        valueFormatter: ({ data }) => {
          const invoice = data as CustomerInvoice;
          return this.getDineroValue(invoice.totalPaidAmount || 0);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("balance"),

        valueFormatter: ({ data }) => {
          const customerInvoices = data as CustomerBalanceResponse;
          return this.getDineroValue(customerInvoices.totalBalanceAmount || 0);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("action"),
        field: "paymentType",
        cellRendererFramework: actionDropdown
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();
  },

  methods: {
    async updateRowData() {
      try {
        const data = await this.$http.get<CustomerBalanceResponse>(
          `${CustomerInvoice.ENDPOINT}?customer_id=${this.router.currentRoute.params.id}`
        );
        data.invoices = data.invoices?.map(ci => CustomerInvoice.from(ci));
        this.rowData = data;
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async applyToSelected() {
      if (this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          this.gridOptions.api?.exportDataAsCsv({ onlySelected: true });
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.exportSuccess")
          });

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    calculateAmountPaid(orderPayments: OrderPayment[]) {
      return orderPayments.reduce(
        (acc, orderPayment) => acc + orderPayment.totalPaidAmount,
        0
      );
    },

    filterItems() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    },

    getDineroValue(amount: number): string {
      const dinero = Dinero({
        amount: amount,
        currency: this.rowData.currency as Currency,
        precision: this.rowData.precision
      });

      return formatDinero(dinero);
    }
  }
});
</script>

<style lang="scss">
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #f5f5f5,
      row-border-color: transparent,
      row-hover-color: null,
      checkbox-checked-color: var(--primary-bg-color),
      range-selection-border-color: var(--primary-bg-color)
    )
  );
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-content-cell-label {
  justify-content: center;
}
</style>
