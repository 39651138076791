
import { defineComponent, onBeforeMount, ref } from "@vue/runtime-core";

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import PaymentInput from "@/views/sales/components/PaymentInput.vue";

import { useI18n } from "vue-i18n";
import { useHTTP } from "@/plugins/http";
import { useAlertModal } from "@/plugins/alert-modal/alert-modal";
import { useRouter } from "vue-router";
import { Route } from "@/router/routes";
import VendorBill, { VendorBillPayment } from "@/models/vendor/VendorBill";

export default defineComponent({
  name: "vendor-bill-payment",

  components: {
    LoadingSpinner,
    AlertBadge,
    HorizontalButton,
    PaymentInput
  },

  setup() {
    const http = useHTTP();
    const alertModal = useAlertModal();
    const router = useRouter();
    const params = router.currentRoute.value.params;
    const loading = ref(false);
    const hasLoaded = ref(false);
    let vendorBill: VendorBill;
    const vendorBillPayment = ref(new VendorBillPayment());
    const error = ref({
      title: "",
      body: ""
    });

    const { t } = useI18n({
      messages: {
        en: {
          successMsg: "Payment made successfully",
          errInvalidAmount: "Invalid amount. Must be bigger > 0",
          errPaymentIsComplete: "Payments are already complete, nothing to pay",
          placePay: "Place payment"
        },
        ar: {
          successMsg: "تم الدفع بنجاح",
          errInvalidAmount: "يجب ان يكون المبلغ المدفوع اكبر من 0",
          errPaymentIsComplete: "تم دفع المبلغ كاملا",
          placePay: "اتمام الدفع"
        }
      }
    });

    onBeforeMount(async () => {
      try {
        loading.value = true;
        const vendorBillResponse = await http.get<VendorBill>(
          `${VendorBill.ENDPOINT}/${params.id}`
        );

        vendorBill = VendorBill.from(vendorBillResponse);

        vendorBillPayment.value.currency = vendorBill.currency || "";
        vendorBillPayment.value.precision = vendorBill.precision || 0;
        vendorBillPayment.value.vendorBillID = vendorBill.id;

        const paymentRemaining = vendorBill.totalBalanceAmount;
        if (paymentRemaining === 0) {
          alertModal.showDanger({
            title: t("errPaymentIsComplete")
          });

          await router.push(Route.ACCOUNTING_VENDORS);
        }
      } catch (err) {
        error.value.title = err.title;
        error.value.body = err.body;
        loading.value = false;
        hasLoaded.value = true;
      } finally {
        loading.value = false;
        hasLoaded.value = true;
      }
    });

    async function submitPayment() {
      if (
        vendorBillPayment.value.paymentAmount &&
        vendorBillPayment.value.paymentAmount <= 0
      ) {
        error.value.title = t("validation.inputErrors");
        error.value.body = t("errInvalidAmount");
        return;
      }
      try {
        await http.post<VendorBillPayment>(
          `${VendorBillPayment.ENDPOINT}`,
          vendorBillPayment.value
        );
      } catch (err) {
        error.value.title = err.title;
        error.value.body = err.body;
      } finally {
        alertModal.showSuccess({
          title: t("successMsg")
        });

        await router.push(Route.ACCOUNTING_VENDORS);
      }
    }
    return {
      t,
      vendorBillPayment,
      submitPayment,
      error,
      loading,
      hasLoaded
    };
  }
});
