<template>
  <div>
    <el-form
      @submit.prevent="validateSubmit('billForm')"
      novalidate
      :model="item"
      :rules="rules"
      label-position="top"
      ref="expenseForm"
      class="flex flex-col items-center"
    >
      <div class="input-section">
        <div class="flex flex-col md:flex-row justify-end">
          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.saveChanges')"
              isSuccess
              faIcon="save"
              @click.prevent="validateSubmit('expenseForm')"
            />
          </div>

          <div class="md:w-3/12 m-2" v-if="enableClearForm">
            <horizontal-button
              :title="$t('actions.clearButton')"
              isDanger
              faIcon="trash"
              @click.prevent="clearForm('expenseForm')"
            />
          </div>
        </div>
      </div>

      <!-- @submit errors -->
      <alert-badge
        isDanger
        :title="error.title"
        :body="error.body"
        @dismissed="
          () => {
            error.title = '';
            error.body = '';
          }
        "
      />

      <!-- Item Name -->
      <div class="input-section">
        <h1>{{ t("sections.newExpense") }}</h1>
      </div>

      <!-- expense amount -->
      <div class="input-section">
        <el-form-item :label="t('amount')" prop="amount" class="input-label">
          <money-input
            id="amount"
            :currency="$ctx.currency"
            v-model="item.amount"
            @dinero-created="dinero => (item.amountDinero = dinero)"
            ref="amountRef"
          />
        </el-form-item>
      </div>

      <!-- payee -->
      <div class="input-section">
        <el-form-item :label="t('payee')" prop="payee" class="input-label">
          <el-input
            id="payee"
            v-model="item.payee"
            :placeholder="t('payee')"
            clearable
            required
          >
          </el-input>
        </el-form-item>
      </div>

      <!-- expense type -->
      <div class="input-section">
        <el-form-item
          :label="t('expenseType')"
          prop="payee"
          class="input-label"
        >
          <el-select
            v-model="item.expenseType"
            filterable
            clearable
            class="w-full"
            required
          >
            <el-option label="Electricity" value="Electricity">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                Electricity
              </span>
            </el-option>
            <el-option label="water" value="water">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                water
              </span>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <!-- expense date -->
      <div class="input-section">
        <el-form-item :label="t('expenseDate')" prop="expenseDate" class="input-label">
          <el-date-picker
            id="expenseDate"
            v-model="item.expenseDate"
            type="date"
            :placeholder="t('expenseDate')"
          >
          </el-date-picker>
        </el-form-item>
      </div>

      <!-- notes -->
      <div class="input-section">
        <el-form-item :label="t('notes')">
          <el-input
            v-model="item.notes"
            :rows="4"
            type="textarea"
            placeholder="Please input"
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// models

import { Expense } from "./Company.vue";
import { expenseI18nMessages } from "./Company.vue";

// components
import HorizontalButton from "@/components/HorizontalButton.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { ElForm } from "element-plus";
import MoneyInput from "@/components/form/MoneyInput.vue";
import { useI18n } from "vue-i18n";
interface Option {
  name: string;
  values: string[];
}
export default defineComponent({
  name: "new-expense-form",
  setup() {
    const messages = {
      en: {
        ...expenseI18nMessages.en,
        sections: {
          newExpense: "New Expense"
        }
      },
      ar: {
        ...expenseI18nMessages.ar,
        sections: {
          newExpense: "New Expense"
        }
      }
    };
    const { t } = useI18n({ messages, useScope: "global" });
    const rules = {
      expenseDate: [{ required: true, message: t("validation.required") }],
      amount: [{ required: true, message: t("validation.required") }],
      payee: [{ required: true, message: t("validation.required") }],
      expenseType: [{ required: true, message: t("validation.required") }]
    };
    return { t, rules };
  },
  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },
  props: {
    initExpense: {
      type: Expense,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      default: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },
  // I have the choice to use v-model but would make this code
  // incredibly complicated!
  // I chose to alias/copy props, even though it might be against vue style guide
  // the parent hence passes an initial copy of the item and variants and DOES NOT
  // expect those inital copies to be synchronized!
  data() {
    return {
      item: this.initExpense,
      error: { title: "", body: "" }
    };
  },
  async beforeMount() {
    // try {
    //   const vendorResp = await this.$http.get<Vendor[]>(Vendor.ENDPOINT);
    //   this.vendors = vendorResp.map(vendor => Vendor.from(vendor));
    // } catch (error) {
    //   this.error.title = error?.title;
    //   this.error.body = error?.body;
    // }
  },
  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      this.item = new Expense();
      this.$globalEmitter.emit("money-input-reset");
      form.resetFields();
    },
    async validateSubmit(formName: string) {
      // attach the amount values
      this.item.amount = this.item.amountDinero?.getAmount();
      this.item.amountCurrency = this.item.amountDinero?.getCurrency();
      this.item.amountPrecision = this.item.amountDinero?.getPrecision();

      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }
        const resp = await this.submitAction(this.item);
        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }
          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
h1 {
  @apply text-lg py-3 font-bold;
}
h2 {
  @apply py-3 font-bold;
}
h3 {
  @apply py-3 font-bold;
}
.input-group {
  @apply flex flex-col justify-between w-full items-end;
}
.input-group-row {
  @apply flex justify-between w-full;
}
.input-group-row > label {
  @apply w-6/12;
}
.input-section {
  @apply rounded w-full justify-center;
}
@screen lg {
  .input-section {
    @apply w-7/12;
  }
}
@screen md {
  .input-section {
    @apply flex-row;
  }
  .input-group {
    @apply flex-row;
  }
  .input-group > .input-label {
    @apply w-6/12;
  }
}
.remove-button {
  @apply text-lg;
  color: var(--danger-text-color);
  align-self: center;
  justify-self: center;
  cursor: pointer;
  margin: 0.5rem;
}
.remove-button:hover {
  color: var(--danger-hover-bg-color);
  align-self: center;
  justify-self: center;
}
.fully-paid-button {
  height: 40px;
}
hr.solid {
  border-top: 1px solid var(--tertiary-bg-color);
}
.hide {
  display: none;
}
.input:hover + .hide {
  display: flex;
  justify-content: center;
}
.danger {
  color: var(--danger-text-color);
  background: var(--danger-bg-color);
}
.success {
  color: var(--success-text-color);
  background: var(--success-bg-color);
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}
tr:nth-child(even) {
  background-color: #f3f3f3;
}
</style>
