<template>
  <div>
    <button
      class="custom-button"
      v-if="params.data.totalBalanceAmount !== 0"
      @click="handleMakePayment"
    >
      {{ buttonLabel }}
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { router } from "@/router";
import { Route } from "@/router/routes";
import { useContext } from "@/plugins/context";

/**
 * This component RUNs inside of Ag-Grid. Hence, it doesn't have access to our
 * default vue instance. Everything has to be explicit. We can't even use i18n.
 * Keep as is, as JS. Don't switch to TS.
 */

export default defineComponent({
  name: "customer-invoice-make-payment",
  setup() {
    const c = useContext();
    const buttonLabel =
      c.locale === "ar" ? "دفع تجاه الفاتورة" : "Make payment";
    return { buttonLabel };
  },

  methods: {
    handleMakePayment() {
      router?.push(
        Route.SALES_ORDERS_ORDER_PAYMENT.replace(":id", this.params?.data.id)
      );
    }
  }
});
</script>

<style>
.custom-button {
  @apply flex items-center justify-center w-full;
  color: blue;
}
</style>
