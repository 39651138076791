<template>
  <div class="m-3" style="height:90vh">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select
        class="input-label"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/inventory/itembrands')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
        <template v-if="$can('delete', '/inventory/itembrands')">
          <el-option
            key="delete"
            :label="$t('views.common.listView.selectedRows.delete')"
            value="delete"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full md:w-6/12"
        prefix-icon="el-icon-search"
        v-model="searchTerm"
      />

      <div class="w-full md:w-3/12">
        <template v-if="$can('create', '/inventory/itembrands')">
          <horizontal-button
            :title="$t('views.accounting.vendors.newBill')"
            @click.prevent="$router.push($Route.ACCOUNTING_VENDORS_NEW_BILL)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import HorizontalButton from "@/components/HorizontalButton.vue";

import VendorBill from "@/models/vendor/VendorBill";
import { useI18n } from "vue-i18n";

import { parseFormat } from "@/utils/date";
import { formatDinero } from "@/utils/money";
import VendorBillMakePayment from "./VendorBillMakePayment.vue";

export default defineComponent({
  name: "vendors",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          vendor: "Vendor",
          billNo: "Bill #",
          billDate: "Bill Date",
          dueDate: "Due Date",
          amountPaid: "Amount Paid",
          balance: "Balance",
          action: "Action",
          total: "Total"
        },
        ar: {
          vendor: "الموزع",
          billNo: "رقم الفاتورة",
          billDate: "تاريخ الفاتورة",
          dueDate: "تاريخ الاستحقاق",
          amountPaid: "المبلغ المدفوع",
          balance: "الرصيد",
          action: "العمليات",
          total: "المجموع"
        }
      },
      useScope: "global"
    });
    return {
      t
    };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: VendorBill[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };
    this.columnDefs = [
      {
        headerName: this.t("vendor"),
        field: "vendorName",
        cellStyle: { textAlign: "center" },
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t("billNo"),
        field: "externalID",
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("billDate"),
        field: "createdAt",
        valueFormatter: params => {
          return parseFormat(params.value);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("dueDate"),
        field: "billDueDate",
        valueFormatter: params => {
          return parseFormat(params.value);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("total"),
        field: "totalDueAmount",
        valueFormatter: params => {
          const vendorBill = params.node?.data as VendorBill;
          return formatDinero(vendorBill.totalDueDinero);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("amountPaid"),
        field: "paidAmount",
        valueFormatter: ({ data }) => {
          const vendorBill = data as VendorBill;
          return formatDinero(vendorBill.totalPaidDinero);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("balance"),
        valueFormatter: ({ data }) => {
          const vendorBill = data as VendorBill;
          return formatDinero(vendorBill.totalBalanceDinero);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("action"),
        field: "id",
        cellRendererFramework: VendorBillMakePayment
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();
    // go to bill view
    // not yet implemented and the route should be to the bill view
    // this.gridOptions.onCellDoubleClicked = event => {
    //   this.$router.push(
    //     this.$Route.INVENTORY_ITEM_BRANDS_BRAND.replace(":id", event.data.id)
    //   );
    // };
  },

  watch: {
    searchTerm() {
      this.filterItems();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        const data = await this.$http.get<VendorBill[]>(VendorBill.ENDPOINT);
        this.rowData = data.map(vendorBill => VendorBill.from(vendorBill));
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }
      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async delete(bills: VendorBill[]) {
      if (
        confirm(this.$t("views.common.listView.selectedRows.deleteConfirm"))
      ) {
        try {
          //await this.$http.delete(Order.ENDPOINT, { data: orders }); should be changed to the vendor bill endpoint
          await this.updateRowData();
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.deleteSuccess")
          });
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          });
        }
      }
    },

    async applyToSelected() {
      if (this.selectedValue === "delete" || this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();
        if (selected?.length) {
          if (this.selectedValue === "delete") {
            // send request to archive
            //await this.delete(selected);
          } else {
            this.gridOptions.api?.exportDataAsCsv({ onlySelected: true });
            this.$alertModal.showSuccess({
              title: this.$t("views.common.listView.selectedRows.exportSuccess")
            });
          }
          // deselect
          selected.length = 0;
        }
      }
      this.selectedValue = "";
    },

    filterItems() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
</script>

<style lang="scss">
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #f5f5f5,
      row-border-color: transparent,
      row-hover-color: null,
      checkbox-checked-color: var(--primary-bg-color),
      range-selection-border-color: var(--primary-bg-color)
    )
  );
}
.ag-header-cell-label {
  justify-content: center;
}
.ag-content-cell-label {
  justify-content: center;
}
</style>
