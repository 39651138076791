
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import actionDropdown from "@/views/accounting/customers/CustomerInvoiceMakePayment.vue";

import { i18nOrderMessages, OrderPayment } from "@/models/sales/Order";
import { useI18n } from "vue-i18n";
import { parseFormat } from "@/utils/date";
import { formatDinero } from "@/utils/money";
import Dinero, { Currency } from "dinero.js";
import { CustomerInvoice } from "@/models/sales/CustomerInvoice";

export default defineComponent({
  name: "customers",

  setup() {
    const { t } = useI18n({
      messages: {
        ...i18nOrderMessages,
        en: {
          customer: "Customer",
          invoiceNo: "Invoice #",
          invoiceDate: "Invoice Date",
          dueDate: "Due Date",
          type: "Type",
          amountPaid: "Amount Paid",
          balance: "Balance",
          action: "Action",
          total: "Total",
          newCustomer: "New Customer"
        },
        ar: {
          customer: "الزبون",
          invoiceNo: "رقم الفاتورة",
          invoiceDate: "تاريخ الفاتورة",
          dueDate: "تاريخ الاستحقاق",
          type: "نوع الفاتورة",
          amountPaid: "المبلغ المدفوع",
          balance: "الرصيد",
          action: "العمليات",
          total: "المجموع",
          newCustomer: "زبون جديد"
        }
      },
      useScope: "global"
    });

    return {
      t
    };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: CustomerInvoice[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("customer"),
        field: "customer.contactDisplayName",
        cellStyle: { textAlign: "center" },
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t("invoiceNo"),
        field: "externalID",
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("invoiceDate"),
        field: "createdAt",
        valueFormatter: params => {
          return parseFormat(params.value);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("dueDate"),
        field: "billDueDate",
        valueFormatter: params => {
          return parseFormat(params.value);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("type"),
        field: "paymentType",
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("total"),
        field: "totalDueAmount",
        valueFormatter: params => {
          const invoice = params.node?.data as CustomerInvoice;
          return formatDinero(invoice.totalDueDinero);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("amountPaid"),
        field: "totalPaidAmount",

        valueFormatter: ({ data }) => {
          const invoice = data as CustomerInvoice;
          const dineroValue = Dinero({
            amount: invoice.totalPaidAmount,
            currency: invoice.currency as Currency,
            precision: invoice.precision
          });
          return formatDinero(dineroValue);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("balance"),

        valueFormatter: ({ data }) => {
          const invoice = data as CustomerInvoice;
          const dineroValue = Dinero({
            amount: invoice.totalBalanceAmount,
            currency: invoice.currency as Currency,
            precision: invoice.precision
          });
          return formatDinero(dineroValue);
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("action"),
        //field: "action",
        cellRendererFramework: actionDropdown
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view order
    // not yet implemented and the route should be to the order view
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(
        this.$Route.ACCOUNTING_CUSTOMER_INVOICES.replace(
          ":id",
          event.data.customerID
        )
      );
    };
  },

  watch: {
    searchTerm() {
      this.filterItems();
    }
  },

  methods: {
    async updateRowData() {
      try {
        this.gridOptions.api?.showLoadingOverlay();
        const invoicesRes = await this.$http.get<CustomerInvoice[]>(
          `${CustomerInvoice.ENDPOINT}`
        );

        this.rowData = invoicesRes.map(invoice =>
          CustomerInvoice.from(invoice)
        );
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
      this.gridOptions.onCellDoubleClicked = event => {
        this.$router.push(
          this.$Route.ACCOUNTING_CUSTOMER_INVOICES.replace(
            ":id",
            event.data.customerID
          )
        );
      };
    },

    async applyToSelected() {
      if (this.selectedValue === "delete" || this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          if (this.selectedValue === "export") {
            this.gridOptions.api?.exportDataAsCsv({ onlySelected: true });
            this.$alertModal.showSuccess({
              title: this.$t("views.common.listView.selectedRows.exportSuccess")
            });
          }

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    calculateAmountPaid(orderPayments: OrderPayment[]) {
      return orderPayments.reduce(
        (acc, orderPayment) => acc + orderPayment.totalPaidAmount,
        0
      );
    },

    filterItems() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
